import { render, staticRenderFns } from "./landing.vue?vue&type=template&id=8cdfdd60&scoped=true&"
import script from "./landing.vue?vue&type=script&lang=js&"
export * from "./landing.vue?vue&type=script&lang=js&"
import style0 from "./landing.vue?vue&type=style&index=0&id=8cdfdd60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cdfdd60",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommunityPost: require('/mnt/volume_berlin_01/frontends/mobile-community/components/CommunityPost.vue').default,MobileNavbar: require('/mnt/volume_berlin_01/frontends/mobile-community/components/mobile-navbar.vue').default})
