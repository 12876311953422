
import global from "~/mixins/global.js";
export default {
  components: {
    footerSection: () => import("@modoc/modoc-mf.components.footer/footer.vue"),
    mobileNavbar: () =>
      import(
        "@modoc/modoc-mf.components.mobile-navbar-new/mobile-navbar-new.vue"
      ),
    navbar: () => import("@modoc/modoc-mf.components.nav-bar/navbar.vue"),
  },
  mixins: [global],
  data() {
    return {
      modalFab: false,
      drawer: false,
      drawerTarget: "",
      user_profile: {
        avatar: "",
        username: "",
        id: null,
      },
      sliderMenuData: [
        {
          id: 1,
          name: "For You",
          onClick: {
            url: "/",
            sameDomain: true,
          },
        },
        {
          id: 2,
          name: "Your Communities",
          onClick: {
            url: "/your-communities",
            sameDomain: true,
          },
          isVisible: this.$store.state.auth.user.isLogin,
        },
        {
          id: 3,
          name: "Listing",
          onClick: {
            url: `${process.env.BASE_COMMUNITY}mbls`,
            sameDomain: false,
          },
        },
        {
          id: 4,
          name: "Discover",
          onClick: {
            url: "/discover",
            sameDomain: true,
          },
        },
      ],
      carouselSettings: {
        arrows: false,
        dots: false,
        infinite: false,
        variableWidth: true,
        //   touchThreshold: 3,
        slidesToScroll: 2,
        // slidesToShow: 3,
      },
      loading: {
        comOwned: false,
        more: false,
      },
      comOwned: {
        lists: [],
        limit: 5,
        total: 0,
        offset: 1,
        empty: false,
      },
      initialLoaded: false,
    };
  },
  mounted() {
    if (this.$store.state.auth.user.isLogin) {
      this.fetchCommunityOwned();
    }
  },
  watch: {
    "$store.state.auth.user.isLogin"() {
      this.sliderMenuData = this.sliderMenuData.map((menu) => {
        if (menu.id === 2)
          return { ...menu, isVisible: this.$store.state.auth.user.isLogin };
        else return menu;
      });
      if (this.$store.state.auth.user.isLogin) {
        this.fetchCommunityOwned();
      }
    },
  },
  methods: {
    closeFab() {
      this.modalFab = false;
    },
    clickFab() {
      this.modalFab = this.$refs["vue-tab"].active;
    },
    handleFabClick(item) {
      switch (item) {
        case "listing":
          this.drawer = true;
          this.drawerTarget = "listing";
          break;
        case "post":
          this.drawer = true;
          this.drawerTarget = "post";
          break;
        default:
          this.$router.push(this.localeLocation("/add"));
          break;
      }
    },
    loadMore() {
      this.comOwned.offset += 1;
      this.fetchCommunityOwned(true);
    },
    async getUserProfile(id) {
      await this.$store
        .dispatch("auth/getUserProfile", id)
        .then((res) => {
          this.user_profile = res.body;
        })
        .catch((err) => {
          throw new Error(err);
        });
      // this.$router.replace('')
    },
    async fetchCommunityOwned(add = false) {
      if (this.loading.comOwned) return;
      this.loading.comOwned = true;
      this.loading.more = true;
      if (!add) {
        this.comOwned.lists = [];
      }
      try {
        const res = await this.$getOwnedCommunities(
          this.comOwned.limit,
          this.comOwned.offset
        );
        if (res.body) {
          this.comOwned.lists.push(...res.body);
        }
        if (this.comOwned.lists.length === 0) {
          this.comOwned.empty = true;
        }
        this.comOwned.total = res.total_records;
      } catch (error) {
        throw new Error(error);
      } finally {
        this.loading.comOwned = false;
        this.loading.more = false;
      }
    },
  },
};
