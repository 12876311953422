
import global from "~/mixins/global.js";
export default {
  components: {
    navbar: () => import("@modoc/modoc-mf.components.nav-bar/navbar.vue"),
    Footer: () => import("~/components/organisms/footer"),
    // Footer: () => import("@modoc/modoc-mf.components.footer/footer.vue"),
    MobileNavbar: () =>
      import(
        "@modoc/modoc-mf.components.mobile-navbar-new/mobile-navbar-new.vue"
      ),
  },
  // layout: "post",
  mixins: [global],
  data: () => ({
    title: "Discussion",
    user_profile: {
      avatar: "",
      username: "",
      id: null,
    },
  }),
  mounted() {
    this.getCommunityDetail();
  },
  methods: {
    async getCommunityDetail() {
      const data = {
        param_id: this.$route.params.id,
        param_slug: "",
      };
      try {
        const communityData = await this.$store.dispatch(
          "public/getCommunityDetail",
          data
        );
        this.$store.commit("community/SET_COMMUNITY_PROFILE", communityData);
        this.$store.commit("auth/SET_MEMBER", communityData.member_data);
      } catch (error) {}
    },
    async getUserProfile(id) {
      await this.$store
        .dispatch("auth/getUserProfile", id)
        .then((res) => {
          this.user_profile = res.body;
        })
        .catch((err) => {
          throw new Error(err);
        });
      // this.$router.replace('')
    },
  },
};
