import { render, staticRenderFns } from "./post.vue?vue&type=template&id=fd2e54be&scoped=true&"
import script from "./post.vue?vue&type=script&lang=js&"
export * from "./post.vue?vue&type=script&lang=js&"
import style0 from "./post.vue?vue&type=style&index=0&id=fd2e54be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd2e54be",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MobileNavbar: require('/mnt/volume_berlin_01/frontends/mobile-community/components/mobile-navbar.vue').default})
